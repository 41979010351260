import "../styles/scss/namePlate.scss";

function NamePlate(){
    return(
        <div class = "namePlate">
            <h1>
            Drew Fisher
            </h1>
        </div>
    );
}

export default NamePlate;