import "../styles/scss/projectCard.scss";
function ProjectCard(props){

    const img = props.img;
    const Title = props.title;
    const desc = props.desc;
    const link = props.link;
    const linkHeader = props.linkHeader;

    return(
        <div class="card">
            <img src ={img} class="card-Img" alt="failed to load"></img>
            <div class = "rows">
            <p class = "rows card-Title">Name: {Title}</p>
            <p class = "rows card-Desc">Project Description: {desc}</p>
            <p class = "rows card-Link">{linkHeader}<a class = "link-link" href={link}>{link}</a></p>
            </div>
        </div>
    );
}

export default ProjectCard;