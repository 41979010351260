import ProjectCard from "./projectCard";
import img_1 from "../files/imgs/mpe.jpg";
import img_2 from "../files/imgs/sleepyworldsviewer.jpg";
import img_3 from "../files/imgs/tttalpha.jpg";
import"../styles/scss/projectsHolder.scss";

function ProjeectsHolder(props){
    const desc_1 = "A spring based monolithic web application Hosted on AWS EC2 with a react client. Allows users to play games of tic-tac-toe.";
    const desc_2 = "A simple javaFX program designed to farm rewards for viewing live streams of the League of legends world championship. Viewing the lolesports.com live stream of the world championship rewarded viewers in game items. The program takes a designated time as a parameter and will open the watchlolesports.com page with the embedded twitch broadcast stream at the provided time allowing users to start the stream while asleep or away from their devices.";
    const desc_3 = "A 2019 recode of my college software engineering class project it uses Javafx to create a GUI and allow users to add mp3s to a library and create playlist.";
    const link_1 = "http://test.tttproto.com/";
    const link_2 = "https://github.com/Hooli-lol/sleepyWorldsViewer";
    const link_3 = "https://github.com/Hooli-lol/SE-Mp3-player-rebuild";
    const title_1 ="TTTProto";
    const title_2 ="SleepyWorldsViewer";
    const title_3 ="MP3 Player";
    const imgs = "";
    const projects =[<ProjectCard img={img_3} title={title_1} desc={desc_1} link ={link_1} linkHeader="Pre-Alpha: "/>,<ProjectCard img={img_2} title = {title_2} desc={desc_2} link={link_2} linkHeader="Repository: "/>, <ProjectCard img={img_1} title ={title_3} desc ={desc_3} link={link_3} linkHeader="Repository: "/>]
    const projcetList = projects.map((project) => 
    <li>{project}</li>);
    return(
        <div class = "projectsHolder">
            <div class = "projects-Banner">Projects</div>
            <ul class = "projectReel">{projcetList}</ul>
            <div class="filler"></div>
            <dice class="footer"></dice>
        </div>
    );

}

export default ProjeectsHolder;