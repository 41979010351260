import "../styles/scss/icons.scss";
function Icons(){

    //onClick event for opening a new page
    const openlink = (link) =>{
        window.open(link);
    }

    return(
    <div class="icons">
        <ul class ="list_of_icons">
            <li><i class="fab fa-github" onClick={() => openlink("https://github.com/Hooli-lol")}></i></li>
            <li><i class="fab fa-linkedin" onClick={() => openlink("https://www.linkedin.com/in/drew-fisher-19b736183")}></i></li>
        </ul>
    </div>
    );
}
export default Icons;