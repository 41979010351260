import Headings from "./Headings";
import Icons from "./icons";
import NamePlate from "./namePlate";
import "../styles/scss/pageheader.scss";

function PageHeader(props){


    return(
        <div class = "pageHeader">
            <NamePlate/>
            <div class="navBar">
            <Headings class="pageHeadings"/>
            <Icons class = "pageIcons"/>
            </div>
            
        </div>
    );
}

export default PageHeader;