import Pdf from "../files/Resume.pdf";
import con from "../files/contact.pdf";
import "../styles/scss/headings.scss";
function Headings(props){

    //onClick event for opening a new page
    const openlink = (link) =>{
        window.open(link);
    }

    //onClick for opening a popup card
    const popUP = (contents) =>{

    }

    return(
        <div class ="Headings">
        <ul class = "HeadingsList">
            <li onClick={() => openlink(Pdf)}><button class="heading-button">Resume</button></li>
            <li onClick ={() => openlink(con)}><button class="heading-button">Contact Info</button></li>
        </ul>
        </div>
    
    );
}

export default Headings;
