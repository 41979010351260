import "./styles/scss/main.scss";
import  PageHeader  from "./components/pageheader";
import ProjeectsHolder from "./components/projectsHolder";

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <PageHeader />
      </div>
      <div className="App-body">
      <ProjeectsHolder />
      </div>
      
    </div>
  );
}

export default App;
